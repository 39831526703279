const domReady = (callback)=>{
    if (document.readyState !== 'loading')
        callback();
    else
        document.addEventListener('DOMContentLoaded', callback);
};

domReady(()=>{
    const gaOnClickElements = Array.prototype.slice.call(document.querySelectorAll('[data-ga-on="click"]'));
    if(!!gaOnClickElements && gaOnClickElements.length){
        const handler = (e)=>{
            const el = e.currentTarget;
            const eventObj = {
                'event_category' : el.dataset?.gaEventCategory || '',
                'event_label' : el.dataset?.gaEventLabel || '',
            };
            window.gtag('event', 'click', eventObj);
            return true;
        };
        gaOnClickElements.forEach((el)=>{
            el.addEventListener('click', handler);
        });
    }
});